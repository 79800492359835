import React from "react"
import Layout from "../components/Layout"
import { Container, Card, Col, Row, Alert } from "react-bootstrap"
import classNames from "classnames"

import ProductImageSliderV2 from "../components/ProductImageSliderV2"

const ReservationSuccess = ({ location }) => {
  
  const product = location?.state?.product
  const formModel = location?.state
  
  return (
    <Layout
      staticSeo={{ seoTitle: "Reservation", seoDescription: "Reservation" }}
      location={{ pathname: "reservationSuccess" }}>
      <Container className={`pt-5`}>
        <h2 className="text-center" style={{ margin: "0 auto" }}>
          Thanks for your reservation           
        </h2>
        <p className="w-100 text-center">
            Click here to return to{" "}
            <Alert.Link href="/">home page</Alert.Link>
          </p>
        <hr />
        <>
          <Row className="justify-content-center mt-5">
            <>
              {formModel && formModel.firstName ? (
                <>
                  <Col lg={6}>
                    <Card className="p-3 pt-5 p-sm-5 border-0 h-100">              
                      <ProductImageSliderV2 product={product} />
                    </Card>
                  </Col>
                  <Col lg={6}>
                    <Card className="border-0">                 
                      <div className="px-3 pt-5 pt-sm-5 px-sm-5">
                      <h3 className={h3HeadingClass}>Bike information</h3>
                        <h4 className={h4HeadingClass}>Name</h4>
                        {/* have to use anchor tag to open product in new tab */}
                        <a href={product.slug} target="_blank">
                          <p>{product.name}</p>
                        </a>
                        <h4 className={h4HeadingClass}>Colour</h4>
                        <p>{product.colour}</p>
                        <div className={serviceRowTotalClass}></div>
                        <h3 className={h3HeadingClass}>Appointment</h3>

                        <h4 className={h4HeadingClass}>DATE OF APPOINTMENT</h4>
                        <p className="mb-0">{formModel.appointmentDate}</p>

                        <h4 className={h4HeadingClass}>STORE OF APPOINTMENT</h4>
                        <p className="mb-0">{formModel.store.name}</p>
                      </div>
                      <hr />
                      <div className="px-3 pt-4 pb-3 px-sm-5">
                        <h3 className={h3HeadingClass}>Your information</h3>
                        <h4 className={h4HeadingClass}>Name</h4>
                        <p>
                          {formModel.firstName} {formModel.lastName}
                        </p>
                        <h4 className={h4HeadingClass}>E-mail</h4>
                        <p>{formModel.email}</p>
                        <h4 className={h4HeadingClass}>Phone number</h4>
                        <p>
                          {formModel.phone !== "" ? formModel.phone : "N/A"}{" "}
                        </p>         
                      </div>
                    </Card>
                  </Col>
                </>
              ) : (
                <Alert variant="danger" className="w-100 text-center">
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p className="w-100">
                    Click here to return to{" "}
                    <Alert.Link href="/">home page</Alert.Link>
                  </p>
                </Alert>
              )}
            </>
          </Row>
        </>
      </Container>
    </Layout>
  )
}

const serviceRowTotalClass = classNames(
  "d-flex",
  "justify-content-between",
  "align-items-center",
  "mt-4"
)
const h4HeadingClass = classNames("text-uppercase", "h6", "text-grey-600")
const h3HeadingClass = classNames("text-uppercase", "h2", "mb-4")

export default ReservationSuccess
